<template>
  <div class="basic-details">
    <h5 class="mb-4">{{ $t("publishing-details") }}</h5>
    <FormGenerator
      :elements="elements"
      :handleUpdate="handleUpdate"
      :data="book"
      inline
      v-bind="$attrs"
    />
  </div>
</template>

<script>
import FormGenerator from "@/components/form-generator";
import { mapState } from "vuex";
import { some } from "lodash-es";

export default {
  name: "basic-details",
  components: {
    FormGenerator
  },
  props: {
    book: {
      type: Object,
      required: true
    },
    handleUpdate: {
      type: Function,
      required: true
    }
  },
  computed: {
    ...mapState("ancillaries", ["languages"]),
    elements() {
      return [
        {
          key: "publisher_name",
          label: "publisher-name",
          type: "string"
        },
        {
          key: "publication_date",
          label: "publication-date",
          type: "date"
        },
        {
          key: "page_count",
          label: "page-count",
          type: "number",
          small: true,
          showIf: [["book_type", "ebook"]]
        },
        {
          key: "image_count",
          label: "image-count",
          type: "number",
          small: true,
          showIf: [["book_type", "ebook"]]
        },
        {
          key: "has_own_isbn",
          label: "do-you-need-isbn",
          type: "switch"
        },
        {
          showIf: [["book_type", "ebook"]],
          key: "epub_isbn",
          label: "epub-isbn",
          type: "string",
          attrs: {
            disabled: !this.book.has_own_isbn
          }
        },
        {
          showIf: [
            ["book_type", "ebook"],
            () => {
              let hasAmazon = false,
                amazonChannelId = 0;

              const channelsByIds = this.$store.state.ancillaries
                .salesChannelsHashMap;

              some(channelsByIds, (v, k) => {
                if (v.name.match(/amazon/i)) {
                  amazonChannelId = k;
                  return true;
                }
              });

              this.book.sales_channels.some(id => {
                if (id == amazonChannelId || id === "retailer") {
                  hasAmazon = true;
                  return true;
                }
              });

              return hasAmazon && this.$store.state.auth.user.role === "admin";
            }
          ],
          key: "asin",
          label: "asin",
          type: "string",
          attrs: {
            disabled: !this.book.has_own_isbn
          }
        },
        {
          showIf: [["book_type", "print"]],
          key: "print_isbn",
          label: "print-isbn",
          type: "string",
          attrs: {
            disabled: !this.book.has_own_isbn
          }
        },
        {
          key: "has_drm",
          label: "digital-rights-management",
          type: "switch",
          showIf: [["book_type", "ebook"]]
        }
      ];
    }
  }
};
</script>

<style></style>
